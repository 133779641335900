/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    jQuery.fn.doesExist = function(){
        return jQuery(this).length > 0;
    };
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
     navToggle: function(){
      $('.navbar-toggle').on('click', function(){
        $('.header-flex').toggleClass('nav-open');
      });
    },
    search: function(){
      $('#search-form').hide();
      $('.fa-search').on('click', function(){
        $(this).hide();
        $('#search-form').slideUp().show();
      });
    },
    slick_home_slider: function(){
       $('.home-slider').slick({
          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3500,
          prevArrow: '<div class="slider-prev"><i class="fa fa-chevron-circle-left fa-3x" aria-hidden="true"></i></div>',
          nextArrow: '<div class="slider-next"><i class="fa fa-chevron-circle-right fa-3x" aria-hidden="true"></i></div>'
        });
    },
     doctors_slider: function(slidesToShow){
       $('.doctors-slider').slick({
          dots: false,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          speed: 500,
          cssEase: 'linear',
          arrows: true,
          prevArrow: '<div class="slider-prev"><i class="fa fa-angle-left fa-2x slider-arrow" aria-hidden="true"></i></div>',
          nextArrow: '<div class="slider-next"><i class="fa fa-angle-right fa-2x slider-arrow" aria-hidden="true"></i></div>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerMode: true,
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
    },
    testimonials_slider: function(){
       $('.testimonials-slider').slick({
          dots: true,
          infinite: true,
          speed: 500,
          cssEase: 'linear',
          arrows: false
        });
    },
    archive_article_click: function(){
      $('.archive-article').on('click', function(){
        window.location = $( this ).find('.archive-article--entry-title a').attr("href");
      });
    },
    tooltipster: function(){
      $('.treatment-area--point').tooltipster({
        side: ['right','bottom','left','top'],
        contentAsHTML: true,
        animation: 'fade',
        delay: 2,
        theme: 'tooltipster-borderless',
        trigger: 'click',
        svg: true,
        interactive: true,
        maxWidth: 300,
        minWidth: 300,
        viewportAware: false,
        contentCloning: true,
        animationDuration: 20
    });
    },
    // All pages
    'common': {
      init: function() {
        Sage.navToggle();
        Sage.testimonials_slider();
        Sage.archive_article_click();
        // JavaScript to be fired on all pages
        $(window).resize(function(){
          // On Resize
        });
      },
      finalize: function() {
        Sage.tooltipster();
        if (!$('body').hasClass('front-page')){
           Sage.doctors_slider(5);
        }
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single_doctors': {
      init: function(){
        
      }
    },
    // Home page
    'front_page': {
      init: function() {
        Sage.slick_home_slider();
        Sage.doctors_slider(4);
        // JavaScript to be fired on the home page
      },
      finalize: function() {
         $(window).load(function(){
          //$('.tooltip-content--elbow').tooltipster('open');
          // Run After Jquery
        });
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
